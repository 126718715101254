@import "@ipen-portal/portal-components/dist/fonts/fonts.css";

html,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}
